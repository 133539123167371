.Wizard-VerticalMenu {
    position: absolute;
    width: 40px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    min-height: 100px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 8px;
}
.WizardVerticalMenu-Action {
    padding: 9px;
    cursor: pointer;
    position: relative;
}
.WizardVerticalMenu-Icon {
    width: 20px;
    height: 20px;
}
.WizardVerticalMenu-Icon_active {
    fill: var(--color-red);
}
.WizardVerticalMenu-Popup {
    white-space: nowrap;
}
.WizardVerticalMenu-SetProjectOwner {
    min-width: 500px;
    min-height: 400px;
}
.WizardVerticalMenu-ModalTitle {
    font-size: var(--font-s-middle);
    font-weight: bold;
    margin-bottom: 20px;
}
.WizardVerticalMenu-ModalText {
    font-size: var(--font-s-middle);
    /* color: var(--color-light-green); */
    margin-bottom: 20px;
}
.WizardVerticalMenu-ModalDescription {
    max-width: 600px;    
}
.WizardVerticalMenu-Link {
    text-decoration: underline;
    font-weight: bold;
}
.WizardVerticalMenu-Button {
    padding: 8px;
}
.WizardVerticalMenu-ModalImages{
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.WizardVerticalMenu-Image{
    width: 100%;
    min-width: 10vw;
    max-width: 200px;
    height: 100%;
    min-height: 6vw;
    max-height: 120px;
    object-fit: cover;
    border-radius: 8px;
}
.WizardVerticalMenu-ModalImage{
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #cfcfcf;
    border-radius: 8px;
    width: 100%;
    min-width: 10vw;
    max-width: 200px;
    height: 100%;
    min-height: 6vw;
    max-height: 120px;
}
.WizardVerticalMenu-ButtonLoader{
    display: flex;
    width: 100%;
    max-width: 176px;
    background-color: #cfcfcf;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 8px;
    cursor: wait;
    margin-bottom: 16px;
}
.WizardVerticalMenu-Loader {
    width: 30px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
      linear-gradient(0deg ,rgb(0 0 0/50%) 30%,#0000 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
      linear-gradient(90deg,rgb(0 0 0/25%) 30%,#0000 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }
  .WizardVerticalMenu-Loader::before,
  .WizardVerticalMenu-Loader::after {
     content: "";
     grid-area: 1/1;
     border-radius: 50%;
     background: inherit;
     opacity: 0.915;
     transform: rotate(30deg);
  }
  .WizardVerticalMenu-Loader::after {
     opacity: 0.83;
     transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {transform: rotate(1turn)}
  }
  